
import SawSVG from '@/components/svg/detail/SawSVG.vue'
export default {
  components: { SawSVG },
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      els: [],
    }
  },
  computed: {
    saws() {
      if (this.$screen.lg) {
        return [
          { y: -200, x: '-128px', pos: { dir: 'left', amount: '0' } },
          { y: 2122, x: '256px', pos: { dir: 'right', amount: '0' } },
          { y: 5642, x: '-50%', pos: { dir: 'left', amount: '50%' } },
          { y: 7942, x: '200px' },
          { y: 11000, x: '-128px', pos: { dir: 'left', amount: '0' } },
        ]
      } else {
        return [
          { y: -64, x: '0px', pos: { dir: 'left', amount: '0' } },
          { y: 1650, x: '0px', pos: { dir: 'right', amount: '0' } },
          { y: 3850, x: '-50%', pos: { dir: 'left', amount: '50%' } },
          { y: 5850, x: '0px', pos: { dir: 'left', amount: '0' } },
          { y: 7350, x: '0px', pos: { dir: 'right', amount: '0' } },
        ]
      }
    },
    sawHeight() {
      return this.$screen.lg ? 1600 : 1100
    },
  },

  beforeMount() {
    this.$nuxt.$on('screenResized', this.anim)
    // this.$nuxt.$on('refreshSaws', this.anim)
    this.$nuxt.$on('go', this.anim)
  },
  beforeDestroy() {
    this.$nuxt.$off('screenResized', this.anim)
    // this.$nuxt.$off('refreshSaws', this.anim)
    this.$nuxt.$off('go', this.anim)
  },

  methods: {
    anim() {
      this.els = []
      this.$nextTick(() => {
        const rect = this.$parent.$refs.section.getBoundingClientRect()
        const top = rect.top + window.scrollY
        const bottom = rect.bottom + window.scrollY

        this.saws.forEach((saw) => {
          if (
            // top of el is between top and bottom of saw
            (top >= saw.y && top <= saw.y + this.sawHeight) ||
            // bottom of el is between top and bottom of saw
            (bottom >= saw.y && bottom <= saw.y + this.sawHeight) ||
            // top of saw is between top and bottom of el
            (saw.y >= top && saw.y <= bottom)
          ) {
            if (top > saw.y) {
              // translate up
              this.els.push({ x: `${saw.x}`, y: `-${top - saw.y}px`, pos: saw.pos })
            } else {
              // translate down
              this.els.push({ x: `${saw.x}`, y: `${saw.y - top}px`, pos: saw.pos })
            }
          }
        })
        this.$nextTick(() => {
          this.els.forEach((el, i) => {
            if (this.$refs.saw) {
              const style = this.$refs.saw[i].style
              style.transform = `translate(${el.x}, ${el.y}`
              if (el.pos) {
                style[el.pos.dir] = el.pos.amount
              }
              this.$gsap.to(this.$refs.svg[i].$el, { delay: 0.5, opacity: 1, duration: 0.7 })
            }
          })
        })
      })
    },
  },
}
