
import { parse } from 'marked'

import HeadingSM from '@/components/wrappers/HeadingSM.vue'
import VerticalLongSVG from '@/components/svg/detail/VerticalLongSVG.vue'
import TriSVG from '@/components/svg/detail/TriSVG.vue'
import Slider from '@/components/parts/Slider.vue'
import Saw from '@/components/parts/Saw.vue'
export default {
  components: {
    HeadingSM,
    VerticalLongSVG,
    Saw,
    TriSVG,
    Slider,
  },
  props: {
    section: {
      type: Object,
      default: () => ({}),
    },
    sectionIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tl: null,
      autoplayer: null,
      saw: false,
    }
  },
  computed: {
    renderedBody() {
      return this.section.body ? parse(this.section.body) : ''
    },
  },
  mounted() {
    this.tl = this.$gsap.timeline({
      defaults: { ease: 'custom.1' },
      scrollTrigger: {
        trigger: this.$el,
        start: 'top bottom',
      },
      onComplete: () => {
        heading.revert()
      },
    })
    this.tl.scrollTrigger.disable()

    this.tl.to(this.$refs.mask, { xPercent: -100, duration: 0.3 })

    this.tl.addLabel('go')

    this.tl.to(this.$refs.slider.$refs.img[0].$el, { opacity: 1, duration: 0.5 }, '>')
    if (this.section.images.data.length > 1) {
      this.autoplayer = this.$gsap.timeline()
      this.autoplayer.set(this.$refs.slider.next, { delay: 3, onRepeat: this.$refs.slider.next, repeat: -1, repeatDelay: 3 })
      this.tl.add(this.autoplayer)
    }

    const heading = new this.$SplitText(this.$refs.heading, { type: 'chars, words, lines' })
    this.tl.from(
      heading.words,
      {
        opacity: 0,
        stagger: {
          each: 0.1,
          from: 'start',
          grid: 'auto',
          ease: 'power2.inOut',
        },
      },
      'go'
    )
    this.$refs.preheading && this.tl.from(this.$refs.preheading.$el, { opacity: 0, xPercent: -10, duration: 0.2 }, '<')

    this.tl.from(this.$refs.tris.children, { opacity: 0, yPercent: -100, stagger: 0.1 }, '>')
    this.$refs.body && this.tl.from(this.$refs.body, { opacity: 0, duration: 0.2 }, '>')

    // this.$emit('sectionMounted')
    // this.saw = true
    // this.$nextTick(() => {
    //   this.$emit('sectionMounted')
    // })
    this.$emit('sectionMounted')
  },
  beforeMount() {
    this.$nuxt.$on('go', this.anim)
  },
  beforeDestroy() {
    this.$nuxt.$off('go', this.anim)
    this.autoplayer.kill()
  },
  methods: {
    anim() {
      this.tl.scrollTrigger.enable()
    },
    stopAutoplay() {
      this.tl.remove(this.autoplayer)
    },
  },
}
