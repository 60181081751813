
import Pagination from '../parts/Pagination.vue'
import AngleSVG from '@/components/svg/img/AngleSVG.vue'
export default {
  components: {
    Pagination,
    AngleSVG,
  },
  props: {
    images: {
      type: Array,
      default: () => {},
    },
    top: {
      type: Boolean,
      deafult: true,
    },
  },
  data() {
    return {
      currentIndex: 0,
      animating: false,
      speed: 0.7,
    }
  },
  computed: {
    isSwiper() {
      return this.images.length > 1
    },
  },
  mounted() {
    if (this.isSwiper) {
      const self = this
      this.$Draggable.create(this.$refs.drag, {
        type: 'x',
        bounds: { minX: -16, maxX: 16 },
        dragResistance: 0.75,
        edgeResistance: 1,
        inertia: true,
        zIndexBoost: false,
        snap: () => {
          return 0
        },
        onDrag() {
          if (this.x > 12) return self.prev()
          if (this.x < -12) return self.next()
        },
      })
    }
  },

  methods: {
    prev() {
      if (this.animating) return
      this.animating = true
      if (this.currentIndex - 1 >= 0) {
        this.$gsap.to(this.$refs.img[this.currentIndex].$el, { opacity: 0, duration: this.speed, ease: 'custom.3' }).then(() => {
          this.currentIndex -= 1
          this.animating = false
        })
      } else {
        this.$gsap.to(this.$refs.img[this.$refs.img.length - 1].$el, { opacity: 1, duration: this.speed, ease: 'custom.3' }).then(() => {
          this.$gsap.set(this.$refs.img.map((x) => x.$el).slice(0, this.$refs.img.length - 1), { opacity: 1 }).then(() => {
            this.currentIndex = this.images.length - 1
            this.animating = false
          })
        })
      }
    },
    next() {
      if (this.animating) return
      this.animating = true
      if (this.currentIndex + 1 <= this.images.length - 1) {
        this.currentIndex += 1
        this.$gsap.to(this.$refs.img[this.currentIndex].$el, { opacity: 1, duration: this.speed, ease: 'custom.3' }).then(() => {
          this.animating = false
        })
      } else {
        this.$gsap.set(this.$refs.img.map((x) => x.$el).slice(1, this.$refs.img.length - 1), { opacity: 0 })
        this.$gsap.to(this.$refs.img[this.$refs.img.length - 1].$el, { opacity: 0, duration: this.speed, ease: 'custom.3' }, '>').then(() => {
          this.currentIndex = 0
          this.animating = false
        })
      }
    },

    goto(i) {
      if (this.animating) return
      this.animating = true
      if (i > this.currentIndex) {
        this.$gsap
          .to(
            this.$refs.img.slice(this.currentIndex, i + 1).map((x) => x.$el),
            { opacity: 1, stagger: 0.1, duration: this.speed, ease: 'custom.3' }
          )
          .then(() => {
            this.currentIndex = i
            this.animating = false
          })
      } else if (i < this.currentIndex) {
        this.$gsap
          .to(
            this.$refs.img.slice(i + 1, this.currentIndex + 1).map((x) => x.$el),
            { opacity: 0, stagger: 0.1, duration: this.speed, ease: 'custom.3' }
          )
          .then(() => {
            this.currentIndex = i
            this.animating = false
          })
      } else {
        this.animating = false
      }
    },
  },
}
