
import ChevronLSVG from '@/components/svg/img/ChevronLSVG.vue'
import ChevronRSVG from '@/components/svg/img/ChevronRSVG.vue'
export default {
  components: {
    ChevronLSVG,
    ChevronRSVG,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    length: {
      type: Number,
      default: 0,
    },
    flexcol: {
      type: Boolean,
      default: false,
    },
    dots: {
      type: Boolean,
      default: true,
    },
  },
}
